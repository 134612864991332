import React, { useState, useEffect } from "react"
import { PortfolioCardContainer } from "../styles/components/PortfolioCard"
import * as ImageComponet from "./Image"
import ReactHtmlParser from "react-html-parser"
import { css } from "styled-components"
import { colorFluerecente } from "../styles/variables"
import { useLang } from "../hooks/useLang"
import Img from "gatsby-image"

export default function ProjectCard(props) {
  const { Image, ProjectInfoEn, ProjectInfoEs, lang } = props

  let { Name, Description } = lang === "en" ? ProjectInfoEn : ProjectInfoEs
  const name = props.Name

  const [reed, setReed] = useState("Reed More")
  const [isToLong, setIsToLong] = useState(false)
  const [isChange, setIsChange] = useState(false)
  const [text, setText] = useState(Description)

  let descriptionArray
  let descriptionLength
  let descriptionShort

  const url = name.toLowerCase().split("-").join("").split(" ").join("-")

  useEffect(() => {
    setText(Description)
    handleText(true)
  }, [lang])

  const handleText = effect => {
    descriptionArray = Description.split(" ")
    descriptionLength = descriptionArray.length
    descriptionShort = Description.split(" ").slice(0, 43).join(" ") + "..."

    if (descriptionLength > 43 && !isChange && effect) {
      setText(descriptionShort)
      setIsToLong(true)
    }
  }

  const handleDescription = () => {
    handleText()

    if (isChange) {
      setText(descriptionShort)
      setReed("Reed More")
    } else {
      setText(Description)
      setReed("Reed Less")
    }
    setIsChange(!isChange)
  }

  return (
    <PortfolioCardContainer id={url}>
      <figure>
        <Img fluid={Image.localFile.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>{Name}</h2>
        <p>{ReactHtmlParser(text)}</p>
        {isToLong && (
          <button
            css={css`
              background: none;
              border: none;
              color: ${colorFluerecente};
              text-decoration: underline;
              font-size: 1rem;
              cursor: pointer;
            `}
            onClick={handleDescription}
          >
            {reed}
          </button>
        )}
      </div>
    </PortfolioCardContainer>
  )
}
