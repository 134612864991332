import styled from 'styled-components';
import { Container } from '..';
import { colorPrincipal, fuenteTitulos } from '../variables';


export const Tabs = styled(Container)`
  display: flex;
  gap: 1em;
  justify-content: flex-end;
  padding-top: 0;
  margin-top: -74px;
  z-index: 800;
  position: relative;
  & a{
      background: ${colorPrincipal};
      color: white;
      border-radius: 8px 8px 0 0;
      font-family: ${fuenteTitulos};
      font-weight: normal;
      text-transform: uppercase;
      padding: .5em 1em;
      font-size: 2rem;
      height: 74px;
      box-sizing: border-box;
      text-decoration: none;
      &.actual-page{
        background: white;
        color: ${colorPrincipal};
      }
  }

  @media (max-width: 500px){
    margin-top: -28px;
    gap: .5em;
    justify-content: center;
    padding: 0;
    & a{
      font-size: .8rem;
      height: auto;

    }
  }
`;