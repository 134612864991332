import * as React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import Hero from "../components/Hero"
// import Pager from "../components/Pager"
import { Container } from "../styles"
import { Tabs } from "../styles/pages/portfolio"
import ProjectCard from "../components/ProjectCard"
import { useLang } from "../hooks/useLang"

const Projects = ({ pageContext }) => {
  const [lang, setLang] = useLang()

  const condition = lang === "en" ? "" : "/es"

  const { name, projects } = pageContext

  const langPage = pageContext.lang

  const title = name.toUpperCase()

  return (
    <>
      <Seo title={title} />
      <Hero bg={"porfolio-bg.png"} title={title} />
      <Tabs>
        <Link activeClassName="actual-page" to={`${condition}/portfolio`}>
          IoT
        </Link>
        <Link activeClassName="actual-page" to={`${condition}/portfolio/web`}>
          Web
        </Link>
        <Link
          activeClassName="actual-page"
          to={`${condition}/portfolio/mobile`}
        >
          Mobile
        </Link>
        <Link
          activeClassName="actual-page"
          to={`${condition}/portfolio/casing`}
        >
          Casing
        </Link>
      </Tabs>

      <section>
        <Container>
          {projects.map(project => (
            <ProjectCard {...project} lang={langPage} />
          ))}
          {/* <Pager/> */}
        </Container>
      </section>
    </>
  )
}

export default Projects
