import styled from 'styled-components';

export const PortfolioCardContainer = styled.article`
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-gap: 4em;
  margin-bottom: 8em;
  & figure{
      position: relative;
      height: 400px;
      & svg{
          position: absolute;
          width: 250px;
          z-index: 800;
          bottom: -150px;
          left: -80px
      }

      & img{
          height: 400px !important;
          object-fit: contain !important;

      }
  }

  & h2{
      text-transform: uppercase;
  }

  @media (max-width: 500px){
      grid-template-columns: 1fr;
      grid-gap: 1em;
      margin-bottom: 4rem;
  }
`;